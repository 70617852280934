/** @jsxImportSource @emotion/react */
import * as React from "react"
import { Global, css } from "@emotion/react"
import { ThemeProvider } from "@mui/material/styles"
import Container from "@mui/material/Container"
import NavBar from "./NavBar"
import theme from "../styles/theme"

const globalCss = css`
  html {
    scroll-behavior: smooth;
  }
  body {
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.primary.main};
    font-family: sans-serif;
  }
  ::selection {
    background-color: ${theme.palette.primary.dark};
  }
  div#main-container {
    max-width: 700px;
    margin-bottom: 120px;
  }
`

const Layout = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <Global styles={globalCss}></Global>
    <Container id="main-container">
      <NavBar />
      {children}
    </Container>
  </ThemeProvider>
)

export default Layout
