import * as React from "react"
import { Helmet } from "react-helmet"

import siteConfig from "../utils/siteConfig.json"

interface SeoProps {
  type: string
  pageTitle?: string
  description?: string
  image?: string
  meta?: any[]
}

const Seo = ({ type, pageTitle, description, image, meta = [] }: SeoProps) => {
  const lang = `ja`
  const title = pageTitle
    ? `${pageTitle} | ${siteConfig.title}`
    : siteConfig.title
  const metaDescription = description || siteConfig.description
  const metaImage = image || `${siteConfig.siteUrl}/images/${siteConfig.icon}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: siteConfig.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteConfig.social.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

export default Seo
