/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import GitHubIcon from "@mui/icons-material/GitHub"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TwitterIcon from "@mui/icons-material/Twitter"
import theme from "../styles/theme"

import siteConfig from "../utils/siteConfig.json"

const socialIconCss = css`
  a {
    text-decoration: none;
  }
  svg {
    padding-top: 5.5px;
    @media (max-width: 450px) {
      width: 0.8em;
      height: 0.8em;
    }
    width: 1em;
    height: 1em;
  }
  color: ${theme.palette.text.primary};
  opacity: 0.92;
  &:hover {
    color: ${theme.palette.text.secondary};
  }
`

const SocialIcon = ({ href, Icon }: any) => (
  <IconButton css={socialIconCss}>
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <Icon />
    </a>
  </IconButton>
)

const NavBarCss = css`
  margin: 0;
  @media (max-width: 600px) {
    padding-top: 4px;
  }
  padding-top: 4.5px;
  margin-left: -4px;
  margin-top: 30px;
  margin-bottom: 76px;
  padding-left: 0;
  padding-right: 0;
`

const NavBarTitleCss = css`
  @media (max-width: 450px) {
    font-size: 18px;
  }
  @media (max-width: 300px) {
    font-size: 16px;
  }
  font-size: 22px;
  font-weight: 600;
  opacity: 0.92;
  color: ${theme.palette.text.primary};
  text-decoration: none;
  &:hover {
    color: ${theme.palette.text.secondary};
  }
`

const NavBar = () => {
  const social = siteConfig.social
  return (
    <Toolbar
      css={css`
        ${NavBarCss}
        @media (min-width: 600px) {
          ${NavBarCss}
        } ;
      `}
    >
      {/* Title */}
      <Box sx={{ flexGrow: 1, justifyContent: "flex-start" }}>
        <Link href="/" css={NavBarTitleCss}>
          {siteConfig.shortTitle}
        </Link>
      </Box>

      {/* Social media buttons */}
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end" }}>
        {social.github && <SocialIcon href={social.github} Icon={GitHubIcon} />}
        {social.linkedin && (
          <SocialIcon href={social.linkedin} Icon={LinkedInIcon} />
        )}
        {social.twitter && (
          <SocialIcon href={social.twitter} Icon={TwitterIcon} />
        )}
      </Box>
    </Toolbar>
  )
}

export default NavBar
