import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    primary: {
      light: "#ffffff",
      main: "#efede7",
      dark: "#e6e3db",
      contrastText: "#212824",
    },
    secondary: {
      light: "#8e9ea2",
      main: "#607073",
      dark: "#364548",
      contrastText: "#212824",
    },
    text: {
      primary: "#212824",
    },
  },
})

export default theme
